@include keyframes(fadein) {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include keyframes(fadeout) {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@include keyframes(fadein-and-fadeout) {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include keyframes(expand-width) {

  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@include keyframes(pulse-animation) {

  from {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.026, 1.056);
  }

  80% {
    transform: scale(0.948, 0.944);
  }

  to {
    transform: scale(1, 1);
  }
}

@include keyframes(card-bounce-flip) {

  from {
    @include rotateY(180deg);
  }

  15% {
    @include rotateY(200deg);
  }

  to {
    @include rotateY(0deg);
  }
}

@include keyframes(card-bounce-show) {

  from {
    visibility: hidden;
    opacity: 0;
  }

  30% {
    visibility: hidden;
    opacity: 0;
  }

  90% {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@include keyframes(card-bounce-hide) {

  from {
    visibility: visible;
    opacity: 1;
  }

  30% {
    visibility: visible;
    opacity: 1;
  }

  90% {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

@include keyframes(card-bounce-fade-out) {

  from {
    visibility: visible;
    opacity: 1;
  }

  20% {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}


@include keyframes(slide-down) {

  from {
    @include transform(translateZ(0) translateY(-100%));
  }

  to {
    @include transform(translateZ(0) translateY(0));
  }
}

@include keyframes(fade-in-later) {
  0%,
  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(fade-out-later) {
  0%,
  30% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include keyframes(fade-in-right) {
  0%,
  30% {
    width: 100%;
    margin-left: 0%;
  }

  100% {
    width: 0%;
    margin-left: 100%;
  }
}

@include keyframes(gentle-bounce--vertical) {

  0%,
  20%,
  50%,
  80%,
  100% {
    @include transform(translateY(0));
  }

  40% {
    @include transform(translateY(-5px));
  }

  60% {
    @include transform(translateY(-2px));
  }
}

// Meandering movement that will remind you of a ghost.
// Used on the onboarding quiz's "ghosting" panel.
@include keyframes(ghost-wobble) {
  0% {
    transform: translate(2px, 5px);
  }

  25% {
    transform: translate(5px, 1px);
  }

  50% {
    transform: translate(0, 0);
  }

  75% {
    transform: translate(-3px, -1px);
  }

  100% {
    transform: translate(2px, 5px);
  }
}

// shake animation for showing wrong password, invalid data, etc.
@include keyframes(shake) {

  0%,
  100% {
    @include transform(translate(0,-1.8em) scale(1,1));
  }

  10%,
  50%,
  90% {
    @include transform(translate(-8px, -1.8em) scale(1,1));
  }

  30%,
  70% {
    @include transform(translate(8px, -1.8em) scale(1,1));
  }
}

// Animation to make it appear as if text is being "typed" out
@include keyframes(type) {

  from { width: 0; }
}

// Animation that rotates a word vertically in a pattern of 6 (see _atom-rotating-word.scss for usage)
@include keyframes(rotateWord) {

  0% { opacity: 0; }

  4% { opacity: 1; }

  8% { opacity: 1; }

  13% { opacity: 0; }

  80% { opacity: 0; }

  100% { opacity: 0; }
}

// Animation that flips an image in 3D space (see _molecule-brady-bunch.scss for usage)
@include keyframes(flip-2) {
  0% { transform: rotateY(-180deg); }

  2% { transform: rotateY(0deg);  }

  49% { transform: rotateY(0deg); }

  51% { transform: rotateY(180deg);  }

  100% { transform: rotateY(180deg);  }
}

@include keyframes(flip-3) {
  0% { transform: rotateY(-180deg); }

  2% { transform: rotateY(0deg);  }

  33% { transform: rotateY(0deg); }

  35% { transform: rotateY(180deg);  }

  100% { transform: rotateY(180deg);  }
}

@include keyframes(flip-4) {
  0% { transform: rotateY(-180deg); }

  2% { transform: rotateY(0deg);  }

  23% { transform: rotateY(0deg); }

  25% { transform: rotateY(180deg);  }

  100% { transform: rotateY(180deg);  }
}

@include keyframes(fade-2) {
  0% { opacity: 0; }

  3% { opacity: 1;  }

  45% { opacity: 1; }

  48% { opacity: 0;  }

  100% { opacity: 0;  }
}

@include keyframes(fade-3) {
  0% { opacity: 0; }

  5% { opacity: 1;  }

  27% { opacity: 1; }

  32% { opacity: 0;  }

  100% { opacity: 0;  }
}

@include keyframes(fade-4) {
  0% { opacity: 0; }

  2% { opacity: 1;  }

  23% { opacity: 1; }

  25% { opacity: 0;  }

  100% { opacity: 0;  }
}

// Animation that creates trailing ellipsis at the end of text
@include keyframes(trailingEllipsis) {

  to { width: 20px; }
}
